import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Button, Typography, Grid, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logoImage from '../assets/logobg.png';

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobileScreen = useMediaQuery("(min-width:240px) and (max-width:600px)");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navItems = [
    { text: 'Home', link: '/' },
    { text: 'About Us', link: 'https://www.getmycollege.com/about-us' },
    { text: 'BCA Colleges', link: 'https://www.getmycollege.com/colleges' },
    { text: 'Contact', link: 'https://www.getmycollege.com/contact-us' },
  ];

  return (
    <>
      <AppBar position="static" sx={{ background: 'linear-gradient(to right, #0D47A1, #42A5F5, #BBDEFB)' }}>
        <Toolbar>
          <a
            href="/"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <img
              src={logoImage}
              alt="Logo"
              style={{
                marginTop: '4px',
                width: isMobileScreen ? "40px" : '70px',
                height: isMobileScreen ? "40px" : '70px',
                cursor: 'pointer',
                borderRadius: '50%',
              }}
            />
          </a>

          <Grid
            container
            sx={{ display: { xs: 'none', md: 'flex' }, ml: 'auto' }}
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
            backgroundColor="Cobalt Blue"
          >
            {navItems.map((item, index) => (
              <Grid item key={index}>
                <Button
                  href={item.link}
                  target={item.text !== "Home" && "_blank"}
                  rel="noopener noreferrer"
                  variant="contained"
                  sx={{
                    backgroundColor: '#005b96',
                    color: '#fff',
                    padding: '8px 16px',
                    borderRadius: '5px',
                    '&:hover': { backgroundColor: '#004080' },
                  }}
                >
                  {item.text}
                </Button>
              </Grid>
            ))}
          </Grid>

          <IconButton
          size='small'
            color="inherit"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{
              display: { xs: 'block', md: 'none' },
              ml: 'auto',
              bgcolor: 'Blue',
              color: '',
              p: 1,
              borderRadius: '8px',
              boxShadow: 3,
              transition: 'transform 0.3s ease, background-color 0.3s ease',
              '&:hover': {
                transform: 'scale(1.06)',
                color:"#fff",
                bgcolor:"Gray"
              },
              '&:active': {
                transform: 'scale(1.1)',
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { width: 240 }, backgroundColor: 'Cobalt Blue',
        }}
      >
        <IconButton
          onClick={handleDrawerToggle}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" sx={{ textAlign: 'center', p: 4 }}>
          Menu
        </Typography>
        <List>
          {navItems.map((item, index) => (
            <ListItem
              button
              key={index}
              component="a"
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: 'primary.dark',
                my: 1,
                px: 3,
                borderRadius: '8px',
                transition: 'all 0.3s ease',
                '&:hover': {
                  color: 'secondary.dark',
                  transform: 'scale(1.05)',
                },
              }}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Header;