import React, { useEffect, useState } from "react";
import Landing from "./Components/landing.js"
import Header from './Components/header.js';
import AutoPopup from "./Components/AutopopUp.js";
import Footer from './Components/footer.js'

function App() {
  const [isAutoPopupOpen, setIsAutoPopupOpen] = useState(false);
 const handleOpenAutoPopup = () => {
    const formSubmitted = sessionStorage.getItem("formSubmitted") === "yes";
    if (!formSubmitted) {
      setIsAutoPopupOpen(true);
    }
  };
  const handleCloseAutoPopup = () => {
    setIsAutoPopupOpen(false);
  };
  useEffect(() =>{
    const timeout = setTimeout(() => {
      handleOpenAutoPopup();
    }, 15000);
  })
  

  return (
    <div>
      <Header />
      <Landing />
      

      <Footer />
      <AutoPopup
          isOpen={isAutoPopupOpen}
          onClose={handleCloseAutoPopup}
          leadFlag="Organic"
        />
    </div>
  );
}

export default App;
