import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    InputAdornment,
    Typography,
    TextField,
    Snackbar,
    Alert,
    Box,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import CancelIcon from "@mui/icons-material/Cancel";
import image from "../assets/logobg.png";

const AutoPopup = ({ isOpen, onClose }) => {
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [isNameValid, setIsNameValid] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    // Function to show Snackbar
    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    // Function to close Snackbar
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    // Form submission handler
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isNameValid || !isPhoneValid) {
            showSnackbar("Please fill in all required fields.", "error");
            return;
        }

        showSnackbar("Form submitted successfully!", "success");
        setName("");
        setPhone("");
        onClose();
    };

    return (
        <>
            <Dialog
                open={isOpen}
                PaperProps={{
                    sx: {
                        border: "5px solid #1d1a57",
                        borderRadius: "8px",
                        overflow: "hidden",
                    },
                }}
                maxWidth="md"
                sx={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    "@media (max-width: 360px)": {
                        width: "360px",
                        height: "800px",
                    },
                    "@media (min-width: 1024px) and (max-width:1440px)": {
                        height: "100%",
                    },
                }}
            >
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                    sx={{
                        position: "absolute",
                        top: { md: "0%", sm: "0.5%", xs: "0.5%" },
                        left: { md: "93%", xs: "82%", sm: "90%" },
                    }}
                >
                    <CancelIcon sx={{ fontSize: "35px", color: "#1d1a57" }} />
                </IconButton>

                <DialogContent
                    sx={{
                        overflow: "hidden",
                        "&.MuiDialogContent-root": { padding: 0 },
                    }}
                >
                    <Grid
                        container
                        md={12}
                        xs={12}
                        sx={{
                            width: "100%",
                            alignItems: "center",
                            justifyItems: "center",
                            overflow: "hidden",
                        }}
                    >
                        <Grid
                            item
                            xs={false}
                            sm={false}
                            md={6}
                            sx={{
                                width: "100%",
                                display: { xs: "none", sm: "none", md: "flex" },
                                alignItems: "center",
                                justifyItems: "center",
                                position: "relative",
                                backgroundImage: `url(${image})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                py: 20,
                            }}
                        >
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                                    zIndex: 1,
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyItems: "center",
                                background: "#fff",
                            }}
                        >
                            <form onSubmit={handleSubmit}>
                                <Grid
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyItems: "center",
                                        px: 3,
                                        borderRadius: 5,
                                    }}
                                >
                                    <Grid
                                        sx={{
                                            pt: { md: 2, xs: 2 },
                                            pb: { md: 3, xs: 1 },
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: { md: "10px", xs: "5px", sm: "10px" },
                                            alignItems: "center",
                                            justifyItems: "center",
                                        }}
                                    >
                                        <Typography
                                            variant="h2"
                                            sx={{
                                                width: "100%",
                                                fontWeight: "600",
                                                fontSize: { xs: "18px", sm: "25px", md: "20px" },
                                                color: "#333333",
                                                textAlign: "center",
                                            }}
                                        >
                                            Apply for Admission
                                        </Typography>
                                    </Grid>
                                    <Grid container spacing={1} md={12} sx={{ gap: "12px" }}>
                                        <Grid item md={12} xs={12} sx={{ my: 1 }}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Name"
                                                name="name"
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                    setIsNameValid(!!e.target.value);
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <PersonIcon style={{ fontSize: 16 }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                inputProps={{
                                                    style: { height: "8px", fontSize: 14 },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12} sx={{ my: 1 }}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Phone Number"
                                                name="phoneNumber"
                                                value={phone}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    const numericInput = input
                                                        .replace(/\D/g, "")
                                                        .slice(0, 10);
                                                    setPhone(numericInput);
                                                    setIsPhoneValid(numericInput.length === 10);
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <PhoneIcon style={{ fontSize: 16 }} />
                                                            +91
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                inputProps={{
                                                    maxLength: 10,
                                                    style: { height: "8px", fontSize: 14 },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <Button
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                sx={{
                                                    my: 2,
                                                    background: "#1d1a57",
                                                    "&:hover": { background: "#1d1a57" },
                                                }}
                                            >
                                                SUBMIT
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                sx={{ width: "auto" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                    sx={{ width: "auto" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default AutoPopup;