import React, { useState } from 'react';
import { TextField, Rating, Button, Box, Typography, Container } from '@mui/material';
import finalImage from '../assets/graduation Image.jpg';

const ReviewForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log({ name, email, rating, review });
        setSubmitted(true);
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                marginY: { xs: 2, md: 8 },
                padding: { xs: 3, md: 4 },
                backgroundColor: '#ffffff',
                borderRadius: 2,
                boxShadow: 6,
                marginLeft: '5px',
                height: { xs: '380px', md: '510px' },
                transform: 'translateY(-50px)',
                transition: 'transform 0.3s, box-shadow 0.3s',
               // marginTop: '50px',
            }}
        >
            {!submitted ? (
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <Typography variant="h5" gutterBottom textAlign="center">
                        Submit Your Review
                    </Typography>

                    <TextField
                    size='small'
                        label="Name"
                        variant="outlined"
                        fullWidth
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        sx={{
                            fontSize: { xs: '0.9rem', sm: '1rem' }
                        }}
                    />

                    <TextField
                        label="Email"
                        size='small'
                        variant="outlined"
                        fullWidth
                        required
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            fontSize: { xs: '0.9rem', sm: '1rem' }
                        }}
                    />

                    <TextField
                        label="Review"
                        size='small'
                        variant="outlined"
                        fullWidth
                        required
                        multiline
                        rows={3}
                        value={review}
                        onChange={(e) => setReview(e.target.value)}
                        sx={{
                            fontSize: { xs: '0.9rem', sm: '1rem' }
                        }}
                    />

                    <Box sx={{ textAlign: 'center' }}>
                        <Typography>Rating</Typography>
                        <Rating
                            name="rating"
                            value={rating}
                            onChange={(e, newValue) => setRating(newValue)}
                            size="small"
                        />
                    </Box>

                    <Box sx={{ textAlign: 'center', mt: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            sx={{
                                backgroundColor: '#1e88e5',
                                '&:hover': {
                                    backgroundColor: '#1565c0',
                                },
                                fontSize: { xs: '0.9rem', sm: '1rem' }
                            }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box textAlign="center" mt={4} sx={{ px: { xs: 2, sm: 4 } }}>
                    <Typography
                        variant="h5"
                        color="success.main"
                        sx={{
                            fontSize: { xs: '1.5rem', sm: '2rem' }, // Font size responsive
                        }}
                    >
                        Thank you for your application!
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{
                            mt: 2,
                            fontSize: { xs: '0.9rem', sm: '1rem' }, // Body text size responsive
                        }}
                    >
                        We appreciate your interest. We will get back to you soon.
                    </Typography>

                    <Box mt={4}>
                        <img
                            src={finalImage}
                            alt="Thank you"
                            style={{
                                width: '80%', // Responsive width
                                height: 'auto',
                                maxWidth: '320px', // Maximum width for large screens
                                borderRadius: '50%',
                                objectFit: 'center',
                                border: '5px solid #4caf50', // Optional border for better styling
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Container>
    );
};

export default ReviewForm;
