import React from 'react';
import { Grid, Typography, Box, Card } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 

// Import images
import CampusImage1 from '../assets/christ.jpg';
import CampusImage2 from '../assets/st joseph.jpg';
import CampusImage3 from '../assets/Justice-College.jpg';

// Import the ReviewForm component
import ReviewForm from './Review';

const FeaturesSection = () => {
  return (
    <div>
      {/* Why Choose Us Section */}
      <Grid
        container
        sx={{
          padding: { xs: 2, sm: 4 },
          backgroundColor: 'lavender',
          marginBottom: 2,
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h5"
            fontWeight="bold"
            gutterBottom
            sx={{
              fontSize: { xs: 23, md: 28 },
              mb: 2,
              textAlign: 'center',
            }}
          >
            Why Choose Us?
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 14, md: 16 },
              lineHeight: 1.8,
              textAlign: 'justify',
            }}
          >
            At Top BCA Colleges Finder, we understand that choosing the right college is one of the most important decisions in your educational journey. 
            Our platform simplifies this process by providing you with a comprehensive and up-to-date list of the best BCA colleges across the country. 
            We offer detailed insights on each college, including courses offered, infrastructure, faculty, placement opportunities, and student reviews. 
            Our user-friendly interface allows you to compare colleges based on your preferences, such as location, ranking, and fees, ensuring that you can make an informed decision. 
            Choose us for accurate, unbiased, and reliable information that will guide you toward a successful career in the field of computer applications.
          </Typography>
        </Grid>
      </Grid>

      {/* Review Form and Image Slider Section */}
      <Grid container spacing={3} sx={{ padding: { xs: 2, sm: 4 } }}>
        {/* Review Form */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <Box
            sx={{
              width: 'auto',
              height: 'auto',
              maxWidth: { xs: '100%', md: '80%' },
              height: '530px',
            }}
          >
            <ReviewForm />
          </Box>
        </Grid>

        {/* Image Slider */}
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Card
            sx={{
              width: 'auto',
              maxWidth: { xs: '100%', md: '100%' },
              height: '520px',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
              boxShadow: 3,
              marginTop:{md:"13px",xs:"22px"}
               
            }}
          >
            <Carousel
              autoPlay
              infiniteLoop
              interval={3000}
              emulateTouch
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              showArrows={false} 
            >
              <div>
                <img 
                  src={CampusImage1} 
                  alt="Campus 1" 
                  style={{ width: '100%', height: '520px', objectFit: 'cover' }} 
                />
              </div>
              <div>
                <img 
                  src={CampusImage2} 
                  alt="Campus 2" 
                  style={{ width: '100%', height: '520px', objectFit: 'cover' }} 
                />
              </div>
              <div>
                <img 
                  src={CampusImage3} 
                  alt="Campus 3" 
                  style={{ width: '100%', height: '520px', objectFit: 'cover' }} 
                />
              </div>
            </Carousel>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default FeaturesSection;
