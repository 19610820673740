import React, { useState, useEffect } from 'react';
import {
  Container, Grid, Card, CardContent, Typography, Button, Box, Chip, Divider, CardMedia, TextField,
} from '@mui/material';

import christUniversityImage from '../assets/christ.jpg';
import globalUniversityImage from '../assets/global.jpeg';
import KristuCollegeImage from '../assets/kristu.jpg';
import JainCollegeImage from '../assets/jain.jpg';
import PesCollegeImage from '../assets/pes.jpg';
import AMCCollegeImage from '../assets/amc.jpg';
import newCardImage1 from '../assets/Global-university.jpg';
import newCardImage2 from '../assets/Justice-College.jpg';
import newCardImage3 from '../assets/christ-university.jpg';
import newCardImage4 from '../assets/college 1.jpeg';
import finalImage from '../assets/graduation Image.jpg';
import FeaturesSection from '../Components/about';

const Landing = () => {
  const topColleges = [
    {
      name: 'Christ University, Bangalore',
      location: 'Bangalore, Karnataka',
      accreditation: 'UGC, NBA Approved',
      fees: '₹ 3-5 Lakn Per Annum',
      placement: '₹ 5-8 Lakn Per Annum',
      rating: '4.3/5',
      //ranking: '#1/167 in India 2024',
      image: christUniversityImage,
    },
    {
      name: 'Global University, Bangalore',
      location: 'Bangalore, Karnataka',
      accreditation: 'UGC, NBA Approved',
      fees: '₹ 3-5 Lakn Per Annum',
      placement: '₹ 20,00,000',
      rating: '4/5',
      //ranking: '#1/167 in India 2024',
      image: globalUniversityImage,
    },
    {
      name: 'Kristu College, Bangalore',
      location: 'Bangalore, Karnataka',
      accreditation: 'UGC, NBA Approved',
      fees: '₹ 1.5-2.5 Lakn Per Annum',
      placement: '6-7 Lakn Per Annum',
      rating: '4.2/5',
      //ranking: '#1/167 in India 2024',
      image: KristuCollegeImage,
    },
    {
      name: 'Jain College, Bangalore',
      location: 'Bangalore, Karnataka',
      accreditation: 'UGC, NBA Approved',
      fees: '₹ 1,50,000',
      placement: '₹ 20,00,000',
      rating: '4.1/5',
     // ranking: '#1/167 in India 2024',
      image: JainCollegeImage,
    },
    {
      name: 'PES Degree college, Bangalore',
      location: 'Bangalore, Karnataka',
      accreditation: 'UGC, NBA Approved',
      fees: '₹ 3-6 Lakn Per Annum',
      placement: '₹ 8-12 Lakn Per Annum',
      rating: '4.4/5',
     // ranking: 'Ranked 32 in NIRF University Rankings.',
      image: PesCollegeImage,
    },
    {
      name: 'AMC Degree College, Bangalore',
      location: 'Bangalore, Karnataka',
      accreditation: 'UGC, NBA Approved',
      fees: '₹ 1-2 Lakn Per Annum',
      placement: '₹ 3-6 Lakn Per Annum',
      rating: '3.8/5',
      //ranking: '#1/167 in India 2024',
      image: AMCCollegeImage,
    },
  ];

  const [showAll, setShowAll] = useState(false);
  const displayedColleges = showAll ? topColleges : topColleges.slice(0, 6);

  const [submitted, setSubmitted] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [newCardImage1, newCardImage3, newCardImage4];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
  };

  return (
    <Box sx={{ backgroundColor: '#E3F2FD  ', py: 4 }}>
      <Container>
        <Grid container spacing={4} alignItems="stretch">
         {/* Image Slider Section */}
         <Grid
            item
            xs={12}
            md={8}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Card
              sx={{
                width: '100%',
                height: { xs: '300px', md: '450px' },
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                boxShadow: 3,
              }}
            >
              <CardMedia
                component="img"
                alt="Image Slide"
                image={images[currentImageIndex]}
                sx={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 2 }}
              
              />
            </Card>
          </Grid>


          {/* Application Form Section */}
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: '400px',
                display: 'flex',
                flexDirection: 'column',
                p: 4,
                borderRadius: 2,
                boxShadow: 3,
              }}
            >
              <CardContent sx={{ flex: 1 }}>
                {!submitted ? (
                  <>
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      gutterBottom
                      sx={{ fontSize: { xs: 23, md: 28 } }}
                    >
                      Application Form
                    </Typography>
                    
                    <form onSubmit={handleSubmit}>
                      <TextField
                        label="Full Name"
                        size='small'
                        variant="outlined"
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        label="Email"
                        size='small'
                        type="email"
                        variant="outlined"
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        label="Phone Number"
                        size='small'
                        type="tel"
                        variant="outlined"
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        label="Enter Your College"
                        size='small'
                        variant="outlined"
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 3
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          sx={{
                            backgroundColor: '#1e88e5',
                            '&:hover': { backgroundColor: '#1565c0' },
                          }}
                        >
                          Submit Application
                        </Button>
                      </Box>
                    </form>
                  </>
                ) : <Box textAlign="center" mt={4} sx={{ px: { xs: 2, sm: 4 } }}>
                <Typography 
                  variant="h5" 
                  color="success.main"
                  sx={{
                    fontSize: { xs: '1.5rem', sm: '2rem' }, // Font size responsive
                  }}
                >
                  Thank you for your application!
                </Typography>
                
                <Typography 
                  variant="body1" 
                  sx={{
                    mt: 2,
                    fontSize: { xs: '0.9rem', sm: '1rem' }, // Body text size responsive
                  }}
                >
                  We appreciate your interest. We will get back to you soon.
                </Typography>
                
                <Box mt={4}>
                  <img
                    src={finalImage}
                    alt="Thank you"
                    style={{
                      width: '80%', // Responsive width
                      height: 'auto', 
                      maxWidth: '320px',  // Maximum width for large screens
                      borderRadius: '50%', 
                      objectFit: 'cover', 
                      border: '5px solid #4caf50',  // Optional border for better styling
                    }}
                  />
                </Box>
              </Box>
              
                  
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* College List Section */}
        <Grid container spacing={3} sx={{ mt: 4 }}>
          {displayedColleges.map((college, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ boxShadow: 3, borderRadius: 2, background:' #fff9c4'}}>
                <CardMedia component="img" alt={college.name} image={college.image} height="200" />
                <CardContent>
                  <Typography variant="h6" fontWeight="bold">{college.name}</Typography>
                  <Typography variant="body2" color="textSecondary">{college.location}</Typography>
                  <Divider sx={{ my: 2 }} />
                 
                  <Typography variant="body2" color="textSecondary">Fees: {college.fees}</Typography>
                  <Typography variant="body2" color="textSecondary">Placement: {college.placement}</Typography>
                  <Typography variant="body2" color="textSecondary">Rating: {college.rating}</Typography>
                  <Typography variant= "body2" color="primary" sx={{ mt: 2 }}> Special:{college.accreditation} </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3
          }}
        >

          <Button
            href="https://www.getmycollege.com/"
            target="_blank"
            rel="noopener noreferrer"
            variant="contained"
            sx={{
              mt: 3, backgroundColor: '#1976d2', color: '#fff',
              '&:hover': { backgroundColor: '#1565c0' },
            }}
          >
            Explore More
          </Button>
        </Box>

        {/* Reviews Section */}
        <Box sx={{ mt: 6, textAlign: 'center' }}>
          <FeaturesSection />
        </Box>
      </Container>
    </Box >
  );
};

export default Landing;
