import React from 'react';
import { Grid, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Grid 
      container 
      direction="column" 
      sx={{ minHeight: '8vh', display: 'flex', flexDirection: 'column' }}
    >
      {/* Main Content Section */}
      <Grid item sx={{ flexGrow: 1 }}>
        {/* Your main content goes here */}
      </Grid>

      {/* Footer Section */}
      <Grid 
        item 
        sx={{
          padding: { xs: '10px 0', md: '20px 0' },  // Responsive padding
          backgroundColor: '#0A2A49',  
          color: 'white',           // White text
          textAlign: 'center',
        }}
      >
        <Typography 
          variant="h2" 
          sx={{ 
            fontSize: { xs: '16px', md: '20px' },  // Responsive font size
          }}
        >
          Copyrights &copy; {new Date().getFullYear()} Top BCA Colleges
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
